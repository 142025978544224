body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-print-color-adjust: exact !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --twentyfoursens-blue: #7c8ce1;
  --twentyfoursens-blue-light: #7f8bdb;
  --twentyfoursens-blue-shade: #e5e8f8;
  --background-white: #ffffff;
  --background-gray: #F5F5F5;
  --font-color-standard: #333333;
  --ion-color-light-gray: rgba(0, 0, 0, 0.54);

  --font-size-heading: 0.95rem;
  --font-size-subheading: 0.8rem;
}

@media print {
  .No-Print {
    visibility: hidden !important;
    width: 0 !important;
  }
  .Display-block-Print{
    display: block;
    max-width: 920px;
  }
  .ecg-plot {
  }
  .no-break-inside {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}

/* Hack to hide text in barchart of plotly rangeslider */
.rangeslider-rangeplot text {
  display: none;
}

.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: var(--ion-color-primary);
  text-decoration: underline;
  cursor: pointer;
  font-family: var(--ion-font-family, inherit);
}
